import React from 'react'
import "./Button.scss";

const Button = ({ className, text, role,to, onClick, children, fluid, ...rest }) => {
    return (
        <button
            type="button"
            {...rest}
            to={to}
            role={role}
            onClick={onClick}
            className={`custom_btn ${fluid ? "w-100" : ""} ${className || ""}`}
        >
            {
                text || children
            }
        </button>
    )
}

export default Button
