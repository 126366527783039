import React from 'react'
import Header from '../../common/Header/Header'
import Footer from '../../common/Footer/Footer'
import "./MainLayout.scss";

const MainLayout = ({ children }) => {
    return (
        <>
            <main className='main_layout'>
                <Header />
                {children}
                <Footer />
            </main>
        </>
    )
}

export default MainLayout
