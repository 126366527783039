import React from "react";
import "./MarketPrices.scss";
import TitleTxt from "../../../common/TitleTxt/TitleTxt";
import downgraph from "../../../../assets/images/down-graph.svg";
import upgraph from "../../../../assets/images/up-graph.svg";
import { Container, Table } from "react-bootstrap";
import btc from "../../../../assets/icons/btc.svg";
import litecoin from "../../../../assets/icons/litecoin.svg";
import polkadot from "../../../../assets/icons/polkadot.svg";
import usdt from "../../../../assets/icons/usdt.svg";
import eth from "../../../../assets/icons/eth.svg";

import tron from "../../../../assets/icons/tron.svg";
import uniswap from "../../../../assets/icons/uniswap.svg";
import { DownArrow, UpArrow } from "../../../../assets/icons/icon";

const MarketPrices = () => {
  const coinsData = [
    {
      id: "btc",
      name: "Bitcoin",
      symbol: "BTC",
      price: "$23,436.20",
      change: "2.93%",
      arrow: <DownArrow />,
      graph: upgraph,
      img: btc,
    },
    {
      id: "USDT",
      name: "USDT",
      symbol: "USDT",
      price: "$23,436.20",
      change: "2.93%",
      arrow: <UpArrow />,
      graph: downgraph,
      img: usdt,
    },
    {
      id: "ethereum",
      name: "Ethereum",
      symbol: "Ethereum",
      price: "$23,436.20",
      change: "2.93%",
      arrow: <UpArrow />,
      graph: downgraph,
      img: eth,
    },
    // {
    //     id: "uniswap",
    //     name: "Uniswap",
    //     symbol: "UNI",
    //     price: "$23,436.20",
    //     change: "2.93%",
    //     arrow: <DownArrow />,
    //     graph: downgraph,
    //     img:uniswap

    // },
    // {
    //     id: "litecoin",
    //     name: "Litecoin",
    //     symbol: "LTC",
    //     price: "$23,436.20",
    //     change: "2.93%",
    //     arrow: <DownArrow />,
    //     graph: downgraph,
    //     img:litecoin

    // }
  ];
  return (
    <section id="market-prices" className="market_prices">
      <Container fluid="lg">
        <TitleTxt title="Market Prices" />
        <div className="table-responsive">
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Price</th>
                <th>Change</th>
                <th>Chart</th>
                <th>Trade</th>
              </tr>
            </thead>

            <tbody>
              {coinsData.map((coin, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <div className="coin_detail">
                      <img src={coin.img} alt={coin.name} />
                      <div>
                        <h4>{coin.name}</h4>
                        <p>{coin.symbol}</p>
                      </div>
                    </div>
                  </td>
                  <td>{coin.price}</td>
                  <td className="change_txt">
                    {coin.arrow} {coin.change}
                  </td>
                  <td>
                    <img src={coin.graph} alt="graph" />
                  </td>
                  <td>
                    <button
                      className="buy_btn"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_ROUTE_HOST}/auth/dashboard`
                        )
                      }
                    >
                      Buy
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>
    </section>
  );
};

export default MarketPrices;
