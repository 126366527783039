import MainLayout from "./components/layouts/MainLayout/MainLayout";
import LandingPage from "./components/pages/LandingPage/LandingPage";

function App() {
  return (
    <>
      <MainLayout>
        <LandingPage />
      </MainLayout>
    </>
  );
}

export default App;
