import React from 'react'
import Banner from './Banner/Banner'
import MarketPrices from './MarketPrices/MarketPrices'
import Trade from './Trade/Trade'
import Features from './Features/Features'

const LandingPage = () => {
    return (
        <>
            <Banner />
            <MarketPrices />
            <Features />
            <Trade />
        </>
    )
}

export default LandingPage
