import "./TitleTxt.scss";
import { motion } from 'framer-motion'

const TitleTxt = ({ className, title, children }) => {
    return (
        <div className={`title_txt ${className || ""}`}>
            <motion.h2
                initial={{
                    opacity: 0,
                    y: 50,
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                }}
                transition={{
                    duration: 0.8,
                    delay: 0.05,
                }}
            >{title || children}</motion.h2>
        </div>
    )
}

export default TitleTxt
