import { useEffect, useState } from "react";
import logo from "../../../assets/logo/logo.svg";
import useScreensize from "../../../hooks/useScreensize";
import "./Header.scss";
import Button from "../Button/Button";
import { useScroll } from "../../../hooks/useScroll";
import { Link } from "react-scroll";
// import { useNavigate } from "react-router-dom";

const NavBar = ({ list, handleActive, active }) => {
  return (
    <div className={`header_nav ${active ? "active" : ""}`}>
      <a href="/" className="header_logo d-lg-none">
        <img src={logo} alt="" />
      </a>
      <ul>
        {list.map((item, index) => {
          return (
            <li key={index}>
              <Link
                onClick={handleActive}
                activeClass="active"
                to={item.to}
                spy={true}
                smooth={true}
                duration={500}
              >
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Header = () => {
  const handleButtonClick = () => {
    let url;
    url = `${process.env.REACT_APP_ROUTE_HOST}/login`;
    window.open(url, "_blank");
  };
  const handlesButtonClick = () => {
    let url;
    url = `${process.env.REACT_APP_ROUTE_HOST}/register`;
    window.open(url, "_blank");
  };

  const width = useScreensize();
  const position = useScroll();
  const navlist = [
    {
      label: "Home",
      to: "banner",
    },
    {
      label: "Buy Crypto",
      to: "market-prices",
    },
    {
      label: "Features",
      to: "features",
    },
    // {
    //     label: "Contact US",
    //     to: "trade",
    // },
  ];
  const [active, setActive] = useState(false);
  const handleClick = () => {
    width < 991 && setActive(!active);
  };
  useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [active]);
  return (
    <>
      {width < 991 && (
        <div
          onClick={handleClick}
          className={`header_backdrop ${active ? "active" : ""}`}
        />
      )}
      <header className={`header ${position > 10 ? "active" : ""}`}>
        <div className="container">
          <div className="header_in">
            {/* <a href="/" className="header_logo">
              <img src={logo} alt="" />
            </a> */}
            <Link
              className="header_logo"
              to="banner"
              spy={true}
              smooth={true}
              duration={100}
            >
              <img src={logo} alt="logo" />
            </Link>
            <div className="header_content">
              <NavBar
                handleActive={handleClick}
                list={navlist}
                active={active}
              />
              <div className={"header_action"}>
                <Button
                  className="action_btn"
                  onClick={() => {
                    handleButtonClick();
                  }}
                >
                  Sign In
                </Button>
                <Button
                  className="action_btn secondary_btn"
                  onClick={() => {
                    handlesButtonClick();
                  }}
                >
                  Sign Up
                </Button>
                <button
                  className={`header_toggle d-lg-none ${
                    active ? "active" : ""
                  }`}
                  onClick={handleClick}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
