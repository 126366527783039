import bannerRight from "../../../../assets/images/banner-right.png";
import bannerLeft from "../../../../assets/images/banner-left.png";
import React from "react";
import "./Banner.scss";
import circleOne from "../../../../assets/images/banner-circle-one.png";
import circleTwo from "../../../../assets/images/banner-circle-two.png";
import { RightArrowIcon, SmallLogo } from "../../../../assets/icons/icon";
import Button from "../../../common/Button/Button";
import coinOne from "../../../../assets/images/banner-coin-one.png";
import coinTwo from "../../../../assets/images/banner-coin-two.png";
import coinThree from "../../../../assets/images/banner-coin-three.png";
import { motion } from "framer-motion";

const Banner = () => {
  const variants = {
    initial: {
      opacity: 0,
      filter: "blur(4px)",
      rotateX: -40,
      y: 100,
    },
    animate: {
      opacity: 1,
      filter: "blur(0rem)",
      rotateX: 0,
      y: 0,
    },
  };

  return (
    <section id="banner" className="banner_sec">
      <img src={bannerRight} className="banner_right_blur" alt="blur" />
      <img src={bannerLeft} className="banner_left_blur" alt="blur" />
      <div className="container">
        <div className="banner_in">
          <motion.img
            initial={{ y: -45 }}
            animate={{ rotate: 180 }}
            whileInView={{ y: 0 }}
            transition={{
              // repeat: Infinity,
              duration: 10,
              type: "spring",
              stiffness: 20,
              repeatType: "mirror",
            }}
            src={circleOne}
            alt="blur-circle"
            className="blur_circle_big"
          />
          <motion.img
            initial={{ y: -45 }}
            animate={{ rotate: 180 }}
            whileInView={{ y: 0 }}
            transition={{
              duration: 20,
            }}
            src={circleTwo}
            alt="blur-circle"
            className="blur_circle_small"
          />
          <motion.img
            initial={{ y: -45 }}
            animate={{ rotate: 360 }}
            whileInView={{ y: 0 }}
            transition={{
              duration: 2,
              type: "spring",
            }}
            src={coinOne}
            alt="coin"
            className="coin_one"
          />
          <motion.img
            initial={{ y: -45 }}
            animate={{ rotate: 360 }}
            whileInView={{ y: 0 }}
            transition={{
              duration: 2,
              type: "spring",
            }}
            src={coinTwo}
            alt="coin"
            className="coin_two"
          />
          <img src={coinThree} alt="coin" className="coin_three" />
          <motion.h1
            variants={variants}
            initial={"initial"}
            whileInView={"animate"}
            transition={{
              duration: 0.6,
            }}
          >
            Next level of <br />
            <SmallLogo /> <span>Crypto Exchange</span>
          </motion.h1>
          <motion.p
            variants={variants}
            initial={"initial"}
            animate={"animate"}
            transition={{
              delay: 0.05,
              duration: 0.6,
            }}
            className="desc_txt"
          >
            Discover the future of finance where innovation meets opportunities
            that suit your preferences.
          </motion.p>

          <Button
            className="start_btn"
            onClick={() =>
              window.open(`${process.env.REACT_APP_ROUTE_HOST}/auth/dashboard`)
            }
          >
            Start Today{" "}
            <span>
              <RightArrowIcon />
            </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Banner;
