export const SmallLogo = () => (

    <svg width="72" height="80" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2935_11944)">
            <path d="M40.0324 20.3921V17.9655C40.0324 15.7399 38.2341 13.9299 35.9929 13.9299C33.7517 13.9299 31.9534 15.7265 31.9534 17.9655V20.2044H25.9008C23.6731 20.2044 21.8613 22.001 21.8613 24.24V47.9571C21.8613 50.1827 23.6596 51.9926 25.9008 51.9926H31.9534V54.2316C31.9534 56.4572 33.7517 58.2671 35.9929 58.2671C38.2341 58.2671 40.0324 56.4706 40.0324 54.2316V51.8049C47.8833 50.8396 54.003 44.2165 54.003 36.1052C54.003 27.994 47.8833 21.3709 40.0324 20.4055V20.3921ZM38.0999 43.9216H29.9538V28.2621H38.0999C42.4212 28.2621 45.9374 31.7747 45.9374 36.0918C45.9374 40.4089 42.4212 43.9216 38.0999 43.9216Z" fill="#E2A92A" />
            <path d="M35.9937 0C14.1186 0.0402212 0.41648 12.6161 0.00044981 39.9933C-0.41558 67.5985 14.9238 80 35.9937 80C57.0636 80 72.4031 67.5985 71.987 39.9933C71.5844 12.6161 57.8823 0.0402212 35.9937 0ZM35.9937 64.1126C18.5339 64.0724 8.11974 53.6015 8.11974 36.0918C8.11974 18.5822 18.5473 8.09787 35.9937 8.05765C53.4536 8.09787 63.8677 18.5688 63.8677 36.0784C63.8677 53.5881 53.4536 64.059 35.9937 64.0992V64.1126Z" fill="#E2A92A" />
        </g>
        <defs>
            <clipPath id="clip0_2935_11944">
                <rect width="72" height="80" fill="white" />
            </clipPath>
        </defs>
    </svg>

)

export const RightArrowIcon = () => (

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 12L13 18M19 12L13 6M19 12L5 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

)

export const DownArrow = () => (

    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L6.266 6.266" stroke="#E14252" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.266 1V6.266H1" stroke="#E14252" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

)

export const UpArrow = () => (

    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 6.26599L6.266 0.999991" stroke="#4ACF67" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.266 6.26599V0.999991H1" stroke="#4ACF67" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

)