import { useEffect, useState } from "react"

const useScreenSize = () => {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        window.onresize = () => {
            setWidth(window.innerWidth)
        }
    }, [])
    return width;
}

export default useScreenSize;