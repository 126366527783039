import React from 'react';
import { Container } from 'react-bootstrap';
import Button from '../../../common/Button/Button';
import TitleTxt from '../../../common/TitleTxt/TitleTxt';
import "./Trade.scss";
import { RightArrowIcon } from '../../../../assets/icons/icon';
import { motion } from 'framer-motion'

const Trade = () => {
    return (
        <section id="trade" className="trade_sec">
            <Container>
                <div className="trade_sec_in">
                    <TitleTxt>Trade With Dynga Anytime. Anywhere. </TitleTxt>
                    <motion.p
                       initial={{ opacity: 0, y: 35 }}
                       whileInView={{ opacity: 1, y: 0 }}
                       transition={{ duration: 0.4, delay: 0.2, }}
                    >Unleash the potential of your investments with our innovative and secure crypto platform.</motion.p>
                    <Button className={"trade_btn"}
                    onClick={()=>window.open(`${process.env.REACT_APP_ROUTE_HOST}/auth/dashboard`)}>Start Today <span><RightArrowIcon /></span></Button>
                </div>
            </Container>
        </section>
    )
}

export default Trade
