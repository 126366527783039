import { useEffect, useState } from "react"

export const useScroll = () => {
    const [position, setPosition] = useState(window.scrollY);
    useEffect(() => {
        window.onscroll = () => {
            setPosition(window.scrollY);
        }
    }, [])
    return position;
}