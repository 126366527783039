import { animate, stagger, useInView } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import crypto_img from "../../../../assets/images/crypto_card.png";
import crypto_network from "../../../../assets/images/crypto_networking.png";
import wallet from "../../../../assets/images/wallet.png";
import TitleTxt from '../../../common/TitleTxt/TitleTxt';
import "./Features.scss";

const Features = () => {
    const ref = useRef();
    const isInView = useInView(ref);

    useEffect(() => {
        let links = [
            [
                "#links",
                {
                    y: [-100, 0],
                    opacity: [0, 1],
                },
                {
                    delay: stagger(0.4),
                    
                },
            ],
        ];
        if (isInView) {
            animate(links)
        }
    }, [isInView])

    return (
        <>
            <section id="features" className="feature_sec">
                <Container>
                    <div className="feature_sec_in">
                        <TitleTxt>Features provided by us</TitleTxt>
                        <Row className="justify-content-center" >
                            <Col lg={4} md={6}>
                                <div className='feature_inner' id="links" ref={ref}>
                                    <h3>Manage your portfolio</h3>
                                    <p>Simplify managing diverse cryptocurrencies on our intuitive platform for a smoother digital asset experience.</p>
                                    <img src={crypto_img} className='crypto_img' alt="crypto" />

                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className='feature_inner'id="links" ref={ref}>
                                    <h3>Crypto Trading</h3>
                                    <p>Experience hassle-free crypto trading while prioritizing safety and security in every transaction for peace of mind.</p>
                                    <img src={wallet} className={"wallet_img"} alt="crypto" />
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className='feature_inner' id="links" ref={ref}>
                                    <h3>Analytical Tools</h3>
                                    <p>Elevate your trading with our powerful analytical tools. Gain insights from advanced charts, indicators, and in-depth analysis for strategic decisions.</p>
                                    <img src={crypto_network} className='crypto_network_img' alt="crypto" />

                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>


        </>
    )
}

export default Features
